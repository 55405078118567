<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <components
          :is="tab.component"
          :popupParam.sync="popupParam"
          :equipData.sync="equipData"
          :contentHeight.sync="contentHeight"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        plantCd: '',
        psmFlag: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'equipmentInfo',
      equipData: {
        plantCd: null,  // 사업장코드
        equipmentTypeCd: null,  // 설비유형 코드
        equipmentTypeName: '',  // 설비유형명
        equipmentCd: '',  // 설비코드
        equipmentNo: '',  // 설비코드
        equipmentName: '',  // 설비명
        mainProcessCd: '',  // 주공정
        subProcessCd: '',  // 부공정
        managementDepts: '',  // 관리부서
        equipmentLocation: '',  // 설비위치
        installDate: '',  // 설치일자
        installVendor: '',  // 설치업체
        opertationDate: '',  // 가동일자
        checkPassNo: '',  // 검사합격번호
        checkPassDate: '',  // 합격일자
        discardFlag: 'N',
        discardDate: '',  // 폐기일자
        psmFlag: 'N',  // PSM 대상 여부
        psmTypeCd: null,
        amount: '',  // 수량
        lawEquipmentFlag: 'N',  // 법정설비 여부
        inspectionCycleCd: null,  // 법정점검 주기
        selfCycleCd: null,  // 자체점검 주기
        pmCycleCd: null,  // 자체점검 주기
        pmFlag: 'N',  // 예방정비 여부
        cmFlag: 'N',  // 고장정비 여부
        predictiveFlag: 'N',  // 예지정비 여부
        operFlag: 'N', // 운전정비 여부
        relatedLaws: '',  // 관련법규
        recentInspectionDate: '',  // 최근 점검일자
        outsourcingVendor: '',  // 유지보수업체
        hazardousFlag: 'N'
      },
      editable: true,
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    tabItems() {
      let _items = [
        { name: 'equipmentInfo', icon: 'info', label: '설비 기본정보', component: () => import(`${'./equipmentInfo.vue'}`) }
      ]
      if (this.popupParam.equipmentCd != '') {
        if (this.popupParam.psmFlag == 'Y') {
          _items.push({ name: 'equipmentInspect', icon: 'construction', label: '점검/정비이력', component: () => import(`${'./equipmentInspect.vue'}`)});
          _items.push({ name: 'equipmentSpec', icon: 'account_tree', label: '설비 사양', component: () => import(`${'./equipmentSpec.vue'}`)});
          _items.push({ name: 'equipmentPsm', icon: 'warning_amber', label: 'PSM 정보', component: () => import(`${'./equipmentPsm.vue'}`) },);
          _items.push({ name: 'equipmentEtc', icon: 'note_alt', label: '관련정보', component: () => import(`${'./equipmentEtc.vue'}`) },);
        } else {
          _items.push({ name: 'equipmentInspect', icon: 'construction', label: '점검/정비이력', component: () => import(`${'./equipmentInspect.vue'}`)});
          _items.push({ name: 'equipmentSpec', icon: 'account_tree', label: '설비 사양', component: () => import(`${'./equipmentSpec.vue'}`)});
          _items.push({ name: 'equipmentEtc', icon: 'note_alt', label: '관련정보', component: () => import(`${'./equipmentEtc.vue'}`) },);
        }
      }
      return _items;
    } 
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.equipment.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.equipmentCd) {
        this.$http.url = this.detailUrl;
        this.$http.param = {equipmentCd: this.popupParam.equipmentCd, plantCd: this.popupParam.plantCd}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.equipData, _result.data);
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data);
        },);
      }
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>